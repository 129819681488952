.page{
    padding: 2%;
    background-color: white;
}


.header {
    position: relative;
    overflow: hidden;
    padding: 10px 13px;
    margin-top: 0px !important;
}
.header .logo{
   display: inline-block;
   float: left;
}


.header .logo {
font-size: 25px;
font-weight: bold;

}


.ran{
  height: 39px;
   width: unset;
   max-width: unset;
   display: initial;
}
@media (min-width: 0px) and (max-width: 768px){
    .ran{
        height: 25px;
        width: unset;
        max-width: unset;
        display: initial;
    }
}

.header-right {
float: right;
margin-top: 10px;
}

.ribbon-right {
    display: flex;
    padding: 10px 22px 15px 35px !important;
    --f: 1%;
    --r: 26px;
    --t: 0px;
  color: white;
  position: absolute;
  font-size: 16px;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 20px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #195750;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
}

@media (min-width: 0px) and (max-width: 768px){
    .ribbon-right {
    padding: 10px 20px 15px 31px !important;
    color: black;
    background: white;
    display:none !important;
}
}

.page-part{
 border: 1px solid darkslategrey;
}

.title{
    font-family: 'Sailec';
    font-size: 20px;
    font-weight: bold;
}
@media (min-width: 0px) and (max-width: 768px){
    .title{
        display:none;

    }

}

.page-body{
    padding-right: 50px;
    padding-left: 50px;

}

@media (min-width: 0px) and (max-width: 767px){
.page-body{
    padding-right: 30px;
    padding-left: 30px;

}
}

.book-appt{
    display: flex;
    line-height: 35px;
    font-size: 18px;   
    padding-bottom: 40px;
}

@media (min-width: 0px) and (max-width: 768px){
    .book-appt{
    display: flex;
    line-height: 27px;
    font-size: 18px;
    font-weight: 550;  
    padding-bottom: 0px !important;
    }
}

.book-text{
    display: flex;
    line-height: 35px;
    font-size: 18px;
}

@media (min-width: 0px) and (max-width: 768px){
    .book-text{
    display: none;
    }
}

.book-text1{
    font-weight: 550;
    padding-top: 25px;
    padding-bottom: 25px;
}



.link{
    color:#367C61;
    text-decoration: underline;
}

/* @media (min-width: 768px) and (max-width: 4000px){
    .book-text1{
    display: none;
    }
} */




.invite-text{
    display: none;
}

@media (min-width: 0px) and (max-width: 768px){
.invite-text{
    display: flex;
    padding-top: 15px;
    padding-bottom: 50px;
    font-weight: 500;
}
}

.page-part-2{
 border-radius: 4px;
 padding: 20px;
 background-color: #346F58;
 background-image: url("../../assets/images/Refer-banner.svg");
 background-repeat: no-repeat;
}

@media (min-width: 0px) and (max-width: 767px){
.page-part-2{
    background-image: url("../../assets/images/Refer-banner-mobile.svg") !important;
    background-repeat: no-repeat;
 
}
}

@media (min-width: 768px) and (max-width: 1024px){
.page-part-2{
 padding: 10px;
 
}
}


.image-parent{
    display: flex;
    justify-content: center;
    margin-top: -75px;
}



.text{
 text-align: center;
 font-weight: bold;
 line-height: 22px;
 color: white;
 font-size: 18px;
}

@media (min-width: 0px) and (max-width: 768px){
    
    .text{
    font-size: 15px;
}
}


.button-parent{
    justify-content: center;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}

.book-button{
    background: white;
    padding: 18px;
    border-radius: 8px;
}

.button-text{
    color: #367C61;
    font-weight: bold;
}

.text-remind{
    color: white;
    text-align: center;
    text-decoration: underline;
   
}

@media (min-width: 768px) and (max-width: 1024px){
    
    .text-remind{
    margin-bottom: 10px;
   
}
}

.remind-parent{
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) and (max-width: 1024px){
.remind-parent{
    margin-bottom: -5px;
}
}

.bold-text{
    padding-top: 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 35px;
}
@media (min-width: 0px) and (max-width: 768px){
    .bold-text{
    display: none;
    }
}


.block-parent{
    background-color: #F4F3F0;
    height: 100%;
}

.block-title{
    text-align: center;
    font-weight: bold;
    font-size: 18px; 
    padding: 25px 6px 25px 6px;
    opacity:60%;
}

@media (min-width: 768px) and (max-width: 1024px){
    .block-title{
        text-align: center;
        padding: 25px;
        font-weight: bold;
        font-size: 18px;
    }
}

.block-desc{
    text-align: center;
    padding: 0px 35px;
    height: 120px;
    opacity:60%;
}

@media (min-width: 1025px) and (max-width: 1235px){
    .block-desc{
    text-align: center;
    padding: 0px 8px;
    height: 140px;
    }
}
@media (min-width: 768px) and (max-width: 1024px){
    .block-desc{
    text-align: center;
    padding: 0px 8px;
    height: 180px;
    }
}

.learn-more-parent{
    display: flex;
    justify-content: center;
    padding: 25px 0px 25px 0px;
    /* background-color: #F4F3F0; */
}

@media (min-width: 767px) and (max-width: 1024px){
    .learn-more-parent{
    display: flex;
    justify-content: center;
    padding: 22% 0px 22% 0px;
    background-color: #F4F3F0;
}
}

.learn-more{
    color: #367C61;
}

.learn-more-icon{
    padding-left: 10px;
}

.blocks{
    display: flex;
    
}
@media (min-width: 0px) and (max-width: 768px){
    .blocks{
    display: none;
    
    }

}


.block-grand{
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.mobile-block{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: #F4F3F0;
    margin-top: 20px;

}
@media (min-width: 769px) and (max-width: 4000px){
    .mobile-block{
        display: none;
    }
}

.foot-sentence-1{
    padding-top: 40px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
}
@media (min-width: 0px) and (max-width: 769px){
    .foot-sentence-1{
   display: none;
    }

}

@media (min-width: 0px) and (max-width: 769px){
    .foot-sentence{
    padding-top: 25px;
    text-align: center;
    padding-bottom: 10px;
    }

}

.foot-sentence-2{
    padding-top: 20px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
}

@media (min-width: 0px) and (max-width: 768px){
    .foot-sentence{
    padding-top: 25px;
    text-align: center;
    padding-bottom: 10px;
    }

}

.success-msg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
